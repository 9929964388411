import React from "react"
import Layout from '../components/Layout'
import { Helmet } from "react-helmet"

export default function Home() {

  return (
    <Layout page="thanks">
      <Helmet>
        <body className="contact-body" />
        <meta charSet="utf-8" />
        <meta name="description" content="Thanks for your enquiry. We will get back to you" /> 
        <title>The Able Guys | Thanks for your submission. We will ge</title>
      </Helmet>      
    
      <div class="spaces vert-center">
        <div class="form-text">
            <h1 class="heading purple">Congrats!<br /> Stage 1 Completed.</h1>
            <p class="description"> <strong class="purple">Chat Unlocked.</strong> We will be in touch.</p>   
        </div>
      </div>   
    </Layout>
  )
}
